<template>
    <div>
        <HeaderNavigation :info="$store.state.infos" :checkValue="5" />
        <div class="top_pic"><img :src="banner" alt=""></div>
        <div class="title_Jour_box">
            <div @click="JumpDetail(item)" v-for="(item,index) of index_new" :key="index" class="title_box">
                <div class="title_Jour_pic"><img :src="item.news_image" alt=""></div>
                <div class="title_Jour_title">{{item.news_title}}</div>
                <div class="title_Jour_time">{{item.created_at}}</div>
            </div>
            <!-- <div v-if="Journalism[1]" class="title_box">
                <div class="title_Jour_pic"><img :src="Journalism[1].pic" alt=""></div>
                <div class="title_Jour_title">{{Journalism[1].title}}</div>
                <div class="title_Jour_time">{{Journalism[1].time}}</div>
            </div> -->
        </div>
        <div class="Jour_list_box">
            <div v-for="(item,index) of info" :key="index">
                <div @click="JumpDetail(item)" :style="index+1==Journalism.length?'border-bottom:1px solid #eee':''" class="Jour_list_content">
                    <div class="Jour_list_left"><img :src="item.news_image" alt=""></div>
                    <div class="Jour_list_right">
                        <div class="Jour_list_title">{{item.news_title}}</div>
                        <div class="Jour_list_desc">{{item.news_introduction}}</div>
                        <div class="Jour_list_time">{{item.created_at}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page_box">
            <Page :total="total" @on-change="pageChange1" :page-size="pageData.limit" :current="pageData.page" show-elevator />
        </div>
        <BottomNavigation :info="$store.state.infos" />
        <RightMenu :info="$store.state.infos" @openpop="openpop" />
        <JoinBulletFrame v-if="JoinModal" @closepop="closepop" />
    <Tips></Tips>
    </div>
</template>

<script>
    import {newInfo,cateBanner} from '@/api/index.js'
    import HeaderNavigation from "@/components/header_navigation.vue";
    import BottomNavigation from "@/components/bottom_navigation.vue";
    import RightMenu from "@/components/right-menu.vue";
    import JoinBulletFrame from "@/components/joinBulletFrame.vue";
import Tips from "@/components/tips.vue";
    export default {
        components: {
            HeaderNavigation,
            BottomNavigation,
            RightMenu,
            JoinBulletFrame,
            Tips
        },
        data(){
            return{
                JoinModal:false,
                banner:'',
                pageData:{
                    page:1,
                    limit:5
                },
                index_new:[],
                info:[],
                Journalism:[],
                total:0
            }
        },
        mounted() {
            window.scroll(0, 0)
            this.cateBanner()
            this.newInfo()
        },
        methods:{
            cateBanner(){
                cateBanner({id:5}).then(res=>{
                    this.banner = res.data
                })
            },
            newInfo(){
                newInfo(this.pageData).then(res=>{
                    this.total = res.data.count
                    this.index_new = res.data.index_new
                    this.info = res.data.info
                })
            },
            openpop(e){
                this.JoinModal = e
            },
            closepop(e){
                this.JoinModal = e
            },
            JumpDetail(e){
                // localStorage.setItem('Journalism',JSON.stringify(e))
                this.$router.push({path:"/newsinformation_detail",query:{id:e.id}})
            },
            pageChange1(e){
                this.pageData.page = e
                this.newInfo()
            }
        }
    }
</script>

<style scoped>
.top_pic{
    width: 100%;
    height: 600px;
}
.top_pic img{
    width: 100%;
    height: 100%;
  object-fit: fill;
}
.title_Jour_box{
    width: 80%;
    margin: 20px auto 30px;
    display: flex;
    justify-content: space-between;
}
.title_box{
    width: 480px;
}
.title_Jour_pic img{
    width: 100%;
    height: 300px;
}
.title_Jour_title{
    color: #39755B;
    font-size: 18px;
}
.title_Jour_time{
    color: #999;
    font-size: 14px;    
}
.Jour_list_box{
    width: 80%;
    margin: 20px auto;
}
.Jour_list_content{
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-top: 1px solid #eee;
}
.Jour_list_left img{
    width:240px;
    height:130px
}
.Jour_list_right{
    margin-left: 30px;
}
.Jour_list_title{
    color: #39755B;
    font-size: 16px;
}
.Jour_list_desc{
    font-size: 14px;
    height: 42px;
    margin: 10px 0;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.Jour_list_time{
    color: #679883;
    font-size: 14px;
}
.page_box{
    display: flex;
    justify-content: center;
    padding: 20px 0 80px;
}
</style>
<style>
    .ivu-page-item-active{
        border-color: #35755A;
        background: #35755A;
    }
    .ivu-page-item-active a, .ivu-page-item-active:hover a{
        color: #fff;
    }
</style>